body {
  background-color: #f9f9f9;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

section {
  margin: 24px 0;
}

a, a:hover {
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.underline {
  text-decoration-line: underline;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-grow {
  flex-grow: 1;
}
