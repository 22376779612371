.table-wrapper .table.product-matrix td, .table-wrapper .table.product-matrix th {
  padding: 0.5rem;
  vertical-align: middle;
  border: 0;
}

.table-wrapper .table.product-matrix tr {
  border: 0;
}

.table-wrapper .table.product-matrix .price-tr {
  border-top: 1px solid #dee2e6;
}

.product-matrix input[type=number] {
  width: 52px;
  height: 40px;
  text-align: right;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.product-matrix__th__total {
  width: 20%;
  text-align: right !important;
  padding-right: 0.5rem;;
}

.product-matrix__td__product-title {
  margin-top: 24px;
}

.product-matrix__th__quantity,
.product-matrix__td__quantity {
  width: 45px;
}

table .product-matrix__th__total,
table .product-matrix__th__unit-cost,
.product-matrix__td__unit-cost,
.product-matrix__td__total {
  text-align: right;
  padding-right: 1.5rem !important;
}


.table-wrapper .table .product-matrix__tr__net-total,
.table-wrapper .table .product-matrix__tr__tax,
.table-wrapper .table .product-matrix__tr__total {
  border-left: 1rem solid #B3E5FC;
  border-right: 1rem solid #B3E5FC;
} 

.product-matrix__tr__net-total td,
.product-matrix__tr__tax td,
.product-matrix__tr__total td {
  text-align: right;
  padding-right: 1rem !important;
}

.table-wrapper .table .product-matrix__tr__net-total > *,
.table-wrapper .table .product-matrix__tr__tax > *,
.table-wrapper .table .product-matrix__tr__total > * {
  font-weight: bold;
  background-color: #B3E5FC;
  border-bottom: none;
}

.table-wrapper .table .product-matrix__tr__tax > * {
  border-bottom: 1px solid #03A9F4;
}

.product-matrix__terms {
  display: flex;
  margin-top: 2em;
  font-size: 0.75em;
}

.product-matrix__terms ul {
  padding: 0.5em 1em;
}

.product-matrix__terms input[type="checkbox"] {
  margin: 0.2em 1em;
}
