.stars {
  display: flex;
}

.star {
  height: 9px;
  width: 9px;
}

.usage__stats {
  margin-bottom: 120px;
}

.usage__table {
  margin-top: 40px;
}

.usage__table__header {
  display: flex;
  justify-content: space-between;
}
.usage__table__header__right-side {
  display: flex;
}
.usage__table__header__right-side > div {
  margin-left: 20px;
}

.usage__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usage--student .usage__table {
  margin-top: 30px;
}

.usage__pagination {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  display: flex;
}

.pagination__item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 35px;
  width: 35px;
  border-radius: 3px;
  cursor: pointer;
}
.pagination__item:hover {
  color: #52a8ae;
  fill: #52a8ae;
  border-color: #52a8ae;
}

.pagination__number {
  margin: 0 10px;
}

#organisation-usage-table, #user-usage-table {
  width: 100% !important;
}

input#usage-date-range {
  min-width: 220px;
}

@media only screen and (max-width: 1200px) {
  .usage__table__header__right-side {
    display: flex;
    flex-direction: column;
  }
  .usage__table__header__right-side .input-wrapper--style-2 {
    width: fit-content !important;
  }
}

.usage__table .dataTables_length, .usage__table .dataTables_filter {
  display: none;
}

.download-vtt {
  border: none;
  background: none;
}

.script-download-option {
  cursor: pointer;
}

.script-download-option:hover {
  text-decoration: underline;
}

.script-download-option > a {
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: black;
}

.script-download-option > a > img {
  margin-right: 10px;
}

.usage-table__truncate {
  max-width: 15vw;
  display: inline-block;
  word-break: break-all;
}
