.navbar {
  width: 100%;
  background-color: #f9f9f9;
}

.navbar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.navbar__left-side {
  display: flex;
  align-items: center;
}

.navbar-link {
  font-size: 16px;
  padding: 5px 3px;
  margin: 0 8px;
  transition: all 0.3s ease-in-out;
}

.navbar-link:hover {
  color: #52a8ae;
}

.navbar__right-side button {
  padding: 15px 20px;
}

.navbar__right-side button:first-child {
  margin-right: 10px;
}

.navbar-mobile {
  position: fixed;
  display: none;
  height: 100vh;
  width: 100vw;
  background-color: #f9f9f9;
  transform: translateX(100%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  top: 0;
}
.navbar-mobile__inner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navbar-mobile__close {
  position: absolute;
  top: 25px;
  right: 24px;
}

.navbar-mobile .navbar-link {
  margin: 10px 0;
  font-size: 20px;
}

.navbar-mobile__links {
  display: flex;
  flex-direction: column;
}

.navbar-mobile--active {
  transform: translateX(0);
}

.site-identity {
  display: grid;
  grid-template-columns: 64px 220px;
}

.site-identity img {
  max-width: 64px;
  height: auto;
}

.site-title {
  margin-top: 16px;
  margin-left: 16px;
  font-family: 'MuseoModerno', cursive;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: black;
}

.sidebar {
  .site-title {
    margin-top: 10px;
    font-size: 24px;
    line-height: 24px;
  }

  .site-identity {
    grid-template-columns: 44px 220px;

    img {
      max-width: 44px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .navbar {
    padding-left: 0;
  }

  .navbar__inner {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar__links {
    display: none;
  }

  .navbar__right-side {
    display: none;
  }
  .navbar__logo img {
    max-width: 150px;
  }
  .navbar-mobile {
    display: block;
  }
}
