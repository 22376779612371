.modal__backdrop {
  position: fixed;
  background: rgba(0,0,0,0.4);
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__container {
  position: relative;
  min-width: 300px;
  min-height: 100px;
  background: white;
  padding: 40px;
  box-shadow: 0 1px 22px rgba(0,0,0,0.2);
  border-radius: 10px;
  text-align: center;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
}

.modal__close svg {
  width: 24px;
}
