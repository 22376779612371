.table-wrapper .table.reseller-orders__table td, .table-wrapper .table.reseller-orders__table th {
  padding: 0.75rem;
  vertical-align: middle;
}

.reseller-orders__table pre {
  margin: 0;
}

.reseller__credit,
.reseller-order-complete__table-wrapper {
  background: #C8E6C9;
  padding: 1.5em;
}

.reseller__credit h5 {
  font-weight: bold;
}


.reseller__credit__summary {
  display: flex;
}
.reseller__credit hr {
  border-bottom: 1px solid #4CAF50;
  margin-bottom: 2em;
}

.reseller__credit__summary > div {
  flex: 1
}

.reseller__credit__payment-details h5 {
  margin-bottom: 1em;
}

.reseller__credit__payment-details p {
  margin-bottom: 0.5em;
}

.reseller__credit__payment-details p span {
  display: inline-block;
  width: 170px;
}

.reseller-order-complete__table {
  background: #C8E6C9;
}

.table-wrapper .reseller-order-complete__table tr > * {
  border: none;
}

.table-wrapper .reseller-order-complete__table tr > td {
  text-align: right;
}

.table-wrapper .table .reseller-order-complete__table__total-row > * {
  border-top: 2px solid #4CAF50;
  font-weight: bold;
}
