.layout {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.layout__inner {
  margin-left: 240px;
  padding: 56px 30px;
  min-height: 100vh;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  .layout__inner {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .layout__inner {
    padding: 56px 15px;
  }
}
