/*Contains css used by the footer in the application layout*/

.footery{
	color:#282828;
	background: #ffffff;
	border-top: 1px solid #C1C0C0;
}

.footer_link{
	color:green;
}

.footery a{
	color:#282828;
	padding: 20px;
	border-top: 20px;
	font-size: 13px;
	display:inline-block;
}

.footery p{
	color:#C1C0C0;
	font-size: 13px;
	padding: 20px;
	font-family: 'hk_groteskregular';
}

.footer_link{
	position: relative;
}