.purchase {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
}

.purchase__inner {
  width: 550px;
}

.purchase__inner > .box {
  border: 10px solid #52a8ae;
  padding: 15px;
}

.purchase p {
  font-size: 12px;
  color: #020202;
}

.purchase a.secondary-link {
  color: #52a8ae;
  font-weight: 700;
}

.new-card {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
}

.purchase .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.purchase .single-action, .billing .single-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
