.label {
  color: white;
  background-color: #34495e;
  padding: 3px 5px;
  border-radius: 4px;
}

.label--positive {
  background-color: #2ecc71;
}

.label--warning {
  background-color: #f39c12;
}

.label--negative {
  background-color: #e74c3c;
}

.label--info {
  background-color: #3498db;
}

.label--pending,
.label--unknown {
  background-color: #6f6f6f;
}
.label--uploading {
  background-color: #3498db;
}

.label--preparing {
  background-color: #dfcb32;
}

.label--capturing,
.label--transcribing,
.label--processing {
  background-color: #f39c12;
}

.label--successful,
.label--capturing_complete {
  background-color: #2ecc71;
}

.label--failed {
  background-color: #e74c3c;
}
