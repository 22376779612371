@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800;900&display=swap");

@import "utils/normalize";
@import "utils/typography";
@import "utils/general";
@import "layout/layout";
@import "components/carescribe";
@import "components/product-matrix";
@import "components/reseller-orders";
@import "components/label";
@import "components/sidebar";
@import "components/table";
@import "components/elements";
@import "components/navbar";
@import "components/input";
@import "components/checkbox";
@import "components/stats";
@import "components/input-search";
@import "components/tooltip";
@import "components/flash";
@import "components/daterange";
@import "components/alert";
@import "components/popover";
@import "components/favicon";
@import "components/impersonating";
@import "components/beta";
@import "components/modal";
@import "components/divider";
@import "components/progress";
@import "components/alert-bar";

@import "pages/sign-in";
@import "pages/sign-up";
@import "pages/sign-up-individual";
@import "pages/university-login-search";
@import "pages/home";
@import "pages/apps";
@import "pages/users";
@import "pages/organisational";
@import "pages/account-details";
@import "pages/usage";
@import "pages/uni-email-login";
@import "pages/uni-not-registered";
@import "pages/password-check";
@import "pages/buy-minutes";
@import "pages/purchase";

table.dataTable th {
  outline: none;
}

table.dataTable thead .sorting_asc .sort-arrows img:first-child {
  opacity: 0.5;
}

table.dataTable thead .sorting_desc .sort-arrows img:last-child {
  opacity: 0.5;
}

#usage-table_filter {
  display: none;
}

#all-users_filter {
  display: none;
}

#purchase-history_filter {
  display: none;
}

.dataTables_length {
  display: none;
}
