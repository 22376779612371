.alert {
  border: 1px solid #e5e5e5;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 15px;
  color: white;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
}

.alert-notice {
  background-color: #4caf50;
  border-color: #4caf50;  
}

.alert-alert {
  background-color: #f44336;
  border-color: #f44336;
}
