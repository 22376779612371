.uni-email-login {
  height: 100%;
  width: 100%;
  margin-top: 120px;
}

.uni-email-login__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uni-email-login__image {
  display: flex;
  align-items: center;
  margin-left: 100px;
  position: relative;
}

.uni-email-login__image__logo__container {
  position: absolute;
  top: 22%;
  right: 20%;
  bottom: 33%;
  left: 29%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uni-email-login__image__background {
  max-width: 540px;
  max-height: 540px;
  width: 100%;
  margin-top: 15%;
}

.uni-email-login__form {
  max-width: 460px;
}

.uni-email-login__form button {
  width: 100%;
  border-radius: 3px;
}

@media only screen and (max-width: 1100px) {
  .uni-email-login__inner {
    flex-direction: column;
  }
  .uni-email-login__image {
    margin-left: 0;
  }
}
