.blank-slate {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 5em 0;

  img { padding-bottom: 2em; }
  &__title { padding-bottom: 0.5em; }
  &__text { padding-bottom: 2em; }
}
