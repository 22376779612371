.divider {
  border-bottom: 1px solid #ddd;
  margin: 20px 0;
}

.divider span {
  position: relative;
  top: 11px;
  background: white;
  padding: 10px;
  font-style: italic;
}
