.stats {
}
.stats__inner {
  display: flex;
  justify-content: space-between;
}

.stat {
  flex: 1;
  display: flex;
  position: relative;
}

.stat__button {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.stat__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  flex-grow: 1;
}

.stat__details__title {
  margin: 5px 0;
}

.stat:not(:last-child) {
  margin-right: 24px;
}

.stats-chart {
}

.stats-chart__inner {
  display: flex;
  flex-direction: column;
}

.stats-chart__element {
  height: 600px;
  width: 100%;
}

.stats-chart__element__container {
  height: 300px;
}

.stat--active {
  background-color: #52a8ae;
}

.stat--active .stat__details {
  width: 100%;
  margin-left: 0;
}

.stat--active .stat__details__title {
  display: none;
}

.stat--active .stat__icon {
  display: none;
}

.stat--active .stat__details__description {
  color: white;
  text-align: center;
}

.stat__overlay {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  font-weight: 600;
}
