.alert-bar {
  position: fixed;
  top: 0;
  left: 0; right: 0;
  z-index: 100;
  background: #53a7ae;
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  
}

.alert-bar .py-3 {
  /* The old dashboard is less forgiving with top-fixed elements and can push "Log out" outside of the viewable area. This overrides the component styling for
     backwards compatibility with the original dashboard and will be removed in the near future. */
  padding: 5px 0 !important;
}

.alert-bar p {
  margin: 0;
}

.alert-bar a {
  color: white;
  font-weight: bold;
}

.alert-bar span.block {
  display: inline-block;
  margin-left: 5px;
}
