.beta {
  background: #222;
  color: white;
  padding: 20px;
  padding-left: 20px;
  padding-bottom: 0px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  border: 2px solid #222;
  align-items: center;
}

.beta:after {
  content: " ";
  border-left: 8px dashed #e6bf00;
  position: absolute;
  top: -5px; left: 0; bottom: -5px;
  transform: skew(0deg, -30deg);
}

.beta > * {
  margin-bottom: 20px;
}

.beta__main {
  flex-grow: 1;
}

.beta__button {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 8px 10px;
  border-radius: 10px;
  color: #ddd;
}

.beta__button:hover {
  border-color: #fff;
  color: #fff;
}

.beta p {
  margin: 0;
  font-size: 0.9em;
  color: #ddd;
}


.beta svg {
  margin-right: 20px;
}


.beta p a {
  color: white;
  font-weight: 600;
}
