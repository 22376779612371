.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext-top {
  visibility: hidden;
  width: 120px;
  padding: 5px 0;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  background-color: white;
  opacity: 0;
  transition: opacity 1s;
  /* POSITIONING */
  z-index: 1;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  margin-left: -60px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(82, 168, 174, 0.2);
  border-radius: 3px;
  box-shadow: 0px 0px 5px 1px rgba(82, 168, 174, 0.2);
}

/* TOP ARROW TOOLTIP */
.tooltip .tooltiptext-top::after {
  position: absolute;
  content: " ";
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip:hover .tooltiptext-top {
  visibility: visible;
  opacity: 1;
}

.tooltiptext-line {
  margin: 5px;
  text-align: left;
}
