.users {
}

.users__main {
}

.users__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users-awaiting {
  margin-bottom: 90px;
}

.users-awaiting__table {
  width: 70%;
}

.users-table__header {
  display: flex;
  justify-content: space-between;
}

.users-table__header__right-side {
  display: flex;
}

.users-table__header__right-side > div {
  margin-left: 20px;
}

.users table .input-wrapper {
  margin-bottom: 0;
}

.users table .input-wrapper input {
  padding: 3px;
  height: auto;
  max-width: 100px;
}

.users-table .dataTables_length, .users-table .dataTables_filter {
  display: none;
}

#user-role {
  width: 200px;
}

@media only screen and (max-width: 1200px) {
  .users-table__header__right-side {
    display: flex;
    flex-direction: column;
  }
  .users-table__header__right-side .input-wrapper--style-2 {
    width: fit-content !important;
  }
}
