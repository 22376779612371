@font-face {
  font-family: 'hk_groteskbold';
  src: '../../fonts/hkgrotesk-bold-webfont.eot';
  src: '../../fonts/hkgrotesk-bold-webfont.eot?#iefix' format('embedded-opentype'),
       '../../fonts/hkgrotesk-bold-webfont.woff2' format('woff2'),
       '../../fonts/hkgrotesk-bold-webfont.woff' format('woff'),
       '../../fonts/hkgrotesk-bold-webfont.ttf' format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'hk_groteskregular';
  src: '../../fonts/hkgrotesk-regular-webfont.eot';
  src: '../../fonts/hkgrotesk-regular-webfont.eot?#iefix' format('embedded-opentype'),
       '../../fonts/hkgrotesk-regular-webfont.woff2' format('woff2'),
       '../../fonts/hkgrotesk-regular-webfont.woff' format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'hk_groteskmedium';
  src: '../../fonts/hkgrotesk-medium-webfont.eot';
  src: '../../fonts/hkgrotesk-medium-webfont.eot?#iefix' format('embedded-opentype'),
       '../../fonts/hkgrotesk-medium-webfont.woff2' format('woff2'),
       '../../fonts/hkgrotesk-medium-webfont.woff' format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'hk_grotesksemibold';
  src: '../../fonts/hkgrotesk-semibold-webfont.eot';
  src: '../../fonts/hkgrotesk-semibold-webfont.eot?#iefix' format('embedded-opentype'),
       '../../fonts/hkgrotesk-semibold-webfont.woff2' format('woff2'),
       '../../fonts/hkgrotesk-semibold-webfont.woff' format('woff');
  font-weight: normal;
  font-style: normal;
}

body{
  background-color: #fcfcfc;
}
.form-inline {
  display: inline-block;
}
.navbar-header.col {
  padding: 0 !important;
}
.navbar {
  font-size: 14px;
  background: #fff;
  padding-left: 16px;
  padding-right: 16px;
}
.navbar .navbar-brand {
  color: #555;
  padding-left: 0;
  font-size: 20px;
  padding-right: 50px;
  font-weight: bold;
}
.navbar .navbar-brand b {
  font-weight: bold;
  color: #f04f01;
}
.navbar ul.nav li {
  font-size: 96%;
  font-weight: bold;
  text-transform: uppercase;
}
.navbar ul.nav li.active a, .navbar ul.nav li.active a:hover, .navbar ul.nav li.active a:focus {
  color: #f04f01 !important;
  background: transparent !important;
}
.search-box {
  position: relative;
}
.search-box input.form-control, .search-box .btn {
  font-size: 14px;
  border-radius: 2px !important;
}
.search-box .input-group-btn {
  padding-left: 4px;
}
.search-box input.form-control:focus {
  border-color: #f04f01;
  box-shadow: 0 0 8px rgba(240,79,1,0.2);
}
.search-box .btn-primary, .search-box .btn-primary:active {
  font-weight: bold;
  background: #f04f01;
  border-color: #f04f01;
  text-transform: uppercase;
  min-width: 90px;
}
.search-box .btn-primary:focus {
  outline: none;
  background: #eb4e01;
  box-shadow: 0 0 8px rgba(240,79,1,0.2);
}
.search-box .btn span {
  transform: scale(0.9);
  display: inline-block;
}
.navbar .nav-item i {
  font-size: 18px;
}
.navbar .dropdown-item i {
  font-size: 16px;
  min-width: 22px;
}
.navbar .nav-item.open > a {
  background: none !important;
}
.navbar .dropdown-menu {
  border-radius: 1px;
  border-color: #e5e5e5;
  box-shadow: 0 2px 8px rgba(0,0,0,.05);
}
.navbar .dropdown-menu li a {
  color: #777;
  padding: 8px 20px;
  line-height: normal;
  font-size: 14px;
}
.navbar .dropdown-menu li a:hover, .navbar .dropdown-menu li a:active {
  color: #333;
}
.navbar .navbar-form {
  border: none;
}
@media (min-width: 992px){
  .form-inline .input-group .form-control {
    width: 225px;
  }
}
@media (max-width: 992px){
  .form-inline {
    display: block;
  }
}
.logoimg {
  margin-right: 15px;
  width: 180px;
}
.main-wrapper {
  position: relative;
  padding: 80px 0;
  overflow: hidden;
}
.main-wrapper img.top-waves{
  position: absolute;
  top: 0px;
  right: -574px;
  z-index: -1;
}
.bottom-waves{
  background-image: '../../images/bottom-waves.png';
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: 0px;
  background-position-y: 160%;
}

.image-width img {
  width: 24px;
}
.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container .custom-select{
  background: #fff;
  font-size: 14px;
  height:45px;
  background-image: '../../images/select-arrow.png';
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
}
.form-container .form-control {
  background: #fafafa;
  font-size: 14px;
  padding: 1.375rem .75rem;
}
.form-container select.form-control {
  padding: 0 .75rem;
  height: 48px;
}
.form-container .form-control.pass {
  border-right: 1px solid #ced4da;
}
.form-container .input-group-text.pass-in-txt.fa {
  background-color: #fff;
  text-align: center;
  font-size: 21px;
  padding: 0 33px 0 14px;
  color: #21b6ee;
}

.form-container .input-group-text {
  background-color: #fafafa;
}
.form-container .form-control::-webkit-input-placeholder { /* Edge */
  color: #808080;
}

.form-container .form-control:-ms-input-placeholder { /* Internet Explorer */
  color: #808080;
}

.form-container .form-control::placeholder {
  color: #808080;
}


.form-container label {
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'hk_groteskbold';
}
.form-container p {
  font-size: 13px;
  margin: 0;
  padding: 30px 35px 15px;
  font-family: 'hk_groteskregular';
}
.form-container p.create-account-txt {
   padding:0 0 15px;
}
.form-container p.sub-title {
  padding: 10px 0 20px;
  font-family: 'hk_groteskregular';
}

.login-form .custum-checkbox {
  margin-bottom: 0px;
}
.login-form input[type="submit"] {
  width: auto;
}
.fogot-pass {
  font-size: 14px;
  color: #21b6ee;
  text-decoration: underline;
  padding: 10px 0 0;
  display: block;
}
.white-card{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 15px #eee;
  padding: 60px 28px 15px 28px;
  min-height: 505px;
  margin-bottom: 35px;
}
.white-card img.width-64{
  width: 64px;
}
.white-card.height-auto {
  min-height: auto;
}
.heading-titles h2 {
  font-size: 32px;
  text-transform: uppercase;
  margin: 0;
  padding: 10px 0;
  color: #000;
  font-family: 'hk_groteskbold';
}
.heading-titles p {
  font-size: 16px;
  color: #000;
  margin:0px 0 24px;
  font-family: 'hk_groteskregular';
}
.blue-txt {
  color: #21b6ee !important;
}
.green-txt {
  color: #5aaf6b !important;
}
.signup-title {
  padding: 0;
  margin: 25px 0;
  color: #000;
  font-size: 22px;
  font-family: 'hk_groteskbold';
}
.btn.btn-blue, .btn.btn-green{
  border-radius: 40px;
  color: #fff;
  padding: 10px 25px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'hk_groteskbold';
}
.btn.btn-blue:hover,
.btn.btn-blue:focus,
.btn.btn-green:hover,
.btn.btn-green:focus {
  color: #fff;
  text-decoration: none;
}
.btn.btn-blue {
  background: #21b6ee;
  box-shadow: 0px 15px 21px -15px #ceeefb;
}
.btn.btn-green {
  background: #5aaf6b;
  box-shadow: 0px 15px 21px -15px #5aaf6b;
}
.btn.btn-login {
  border: 1px solid #21b6ee;
  border-radius: 40px;
  font-size: 14px;
  color: #21b6ee;
  text-transform: uppercase;
  margin-left: 20px;
  padding: 10px 25px;
  font-family: 'hk_groteskbold';
}
.btns-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signup-footer {
  font-size: 14px;
  font-family: 'hk_groteskregular';
}

@media (max-width: 992px){
  .form-container {
  width: 100%;
  margin: 0 auto;
  }
  .btn.btn-blue, .btn.btn-green {
    font-size: 12px;
    padding: 10px;
  }
}

.notification-box {
  background: #eef8f0;
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  color: #000;
  font-style: oblique;
}

/**Custom Checkbox***/
.checkbox-container {
  margin: 30px 0;
}
.custum-checkbox a {
  color: #333;
  text-decoration: underline;
}
.custum-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize !important;
  font-family: 'hk_groteskregular' !important;
}

/* Hide the browser's default checkbox */
.custum-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custum-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #20b7ee;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.custum-checkbox:hover input ~ .checkmark {
  background-color: #20b7ee;
}

/* When the checkbox is checked, add a blue background */
.custum-checkbox input:checked ~ .checkmark {
  background-color: #20b7ee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custum-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custum-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custum-checkbox .checkmark:after {
  left: 8px;
  top: 2px;
  width: 10px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Payment page editing*/

.captioned_payment{
  color: #212529;
  font-family: 'hk_groteskbold';
}

.captioned_payment h3{
  font-size:16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 40px;
}

.captioned_payment p{
  padding: 0px;
  margin-bottom: 20px;
}

/*CSS FOR DASHBOARD WELCOME MESSAGE*/
.welcome_banner{
  background-color:#DAF1F2!important;
  color: #000;
}

.welcome_message h4{
  font-size: 20px;
  text-transform: uppercase;
}

.download_link{
    width: 400px;
    height: 80px;
    border-radius: 15px;
    background: white;
    box-shadow: 0 10px 10px 0 rgba(34,182,239,0.35);
    padding: 10px 20px;
    color: #000;
    /*width: fit-content;*/
}

.download_link:hover{
  /*background: #ecf0f1;*/
  /*background: #FDFAFA;*/
  opacity:0.8;
  /*color: white;*/
}

.download_link .download_logo{
  width: 60px;
  height: 60px;
  vertical-align: top!important;
}

.download_button_words{
  display: inline-block;
  line-height: 1.2;
  padding-top: 5px;
  padding-top: 5px;
  padding-left: 10px;

}

.download_small_message{
  font-size: 12px;
  font-weight: bold;
  padding-top: 10px;
}

.download_big_message{
  font-size: 24px;
  font-weight: bold;
  display: block;
}

.download_link_arrow{
    width: 10px;
    height: 22px;
    margin-left: 20px;
    vertical-align: inherit!important;
}

.closer_tesy{
  color: grey;
  float: right;
  font-size: 12px;
}

.organisation-users-container {
  .custom-search {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-right: none;
  }

  .input-group-text {
    background-color: #fff !important;
  }
}
