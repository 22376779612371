.apps {
}

.apps__inner {
}

.app {
  max-width: 264px;
  height: 232px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apps-list {
  display: grid;
  grid-gap: 70px 30px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(254px, 1fr));
  flex-wrap: wrap;
  margin-top: 100px;
}

.app__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -20px;
  text-align: center;
}

.app__icon {
  margin-bottom: 20px;
}

.app__unavailable-text {
  position: absolute;
  height: 50px;
  bottom: -80px;
  text-align: center;
  font-size: 12px;
}

.app__button {
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}

.app__button > button {
  padding: 15px 30px;
  white-space: nowrap;
}
