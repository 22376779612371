.sides__wrapper {
  display: flex;
}

.title {
  margin-bottom: 40px;
}

.subtitle {
  margin-bottom: 25px;
}

.add__another img {
  margin-right: 10px;
}

.add__another p {
  font-weight: bold;
  font-size: 12px;
  text-decoration: underline;
}
.add__another {
  display: inline-flex;
  align-items: center;
  color: #020202;
}

.university__logo {
  margin-top: 30px;
}

.university__logo h2 {
  margin-top: 0;
}

.university__buttons {
  display: flex;
}

.image__paragraph {
  display: flex;
}

.image__paragraph p {
  color: #020202;
}
.image__paragraph img {
  flex-shrink: 0;
  margin-right: 40px;
}
.source__paragraph {
  font-size: 12px;
  margin-bottom: 30px;
  line-height: 16px;
}

.source__add-another {
  margin-left: 28px;
  margin-bottom: 30px;
}

.image__paragraph p {
  font-size: 12px;
  line-height: 16px;
}

.university__buttons {
  display: flex;
  margin-top: 20px;
}
.button--upload {
  margin-right: 14px;
}
.button--send {
  background-color: white !important;
  color: black !important;
  border: 1px solid #e5e5e5 !important;
}

.button--send:hover {
  border: 1px solid #52a8ae !important;
  color: #52a8ae !important;
}

.button--send:active {
  background: #dafdff !important;
}

.control {
  margin-top: 30px;
}

.control p {
  font-size: 12px;
  line-height: 16px;
  color: #020202;
}

.control .control--input::placeholder {
  color: black;
  font-weight: 400;
}

.control__buttons {
  display: flex;
  justify-content: flex-end;
}

.right__side {
  margin-left: 20px;
  margin-top: -72px;
}

.remove__td {
  margin-left: 16px;
}
.user__title {
  margin-bottom: 20px;
  margin-top: 30px;
}

.usage__title {
  margin-bottom: 20px;
  margin-top: 30px;
}
.right__side .table {
  padding-bottom: 0;
  margin-bottom: 0;
}
.right__side .box__table {
  padding: 0;
}

.organisational .table tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}

.table .checkbox-wrapper {
  width: fit-content;
}

.hide-email {
  display: none;
}

.hide-url {
  display: none;
}

.hide-email {
  display: none;
}

.hide-url {
  display: none;
}

.block {
  display: block;
}

.url-margin {
  margin: 0 20px;
}
