.minutes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.minutes__inner {
  text-align: center;
  max-width: 552px;
}

.minutes__subtitle {
  margin-bottom: 40px;
}

.minutes__checks {
  display: flex;
  align-items: center;
  margin-bottom: -10px;
}

.minutes__checks input {
  cursor: pointer;
  margin-right: 16px;
  margin-left: 5px;
  -webkit-appearance: none;
  position: relative;
  outline: none;
  width: 18px;
  height: 18px;
  border: 2px solid #42999e;
  border-radius: 50%;
}

.minutes__checks input:checked {
  background: #81e7ec;
  border-radius: 50%;
  background-color: white;
}

.minutes__checks input:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  top: 3px;
  left: 3px;
}

.minutes__checks input:checked:before {
  background: #51a8ad;
}

.minutes__input {
  text-align: start;
  font-size: 14px;
}

.card__details {
  display: flex;
  justify-content: space-between;
}

.box {
  text-align: start;
}

.minutes__button {
  border-radius: 5px;
  color: white;
  padding: 1.25rem 1.8rem;
  text-align: center;
  background: #42999e;
  width: 100%;
}

.short {
  width: 140px;
}

.payment-card {
  cursor: pointer;
  margin-bottom: 10px;
}

.payment-card.compact {
  padding: 10px;
}

.payment-card.compact .payment__img {
  width: 40px;
  height: 40px;
}

.cards-list {
  margin-bottom: 30px;
}

#card-errors {
  font-size: 0.8rem;
  color: #f44336;
}

.new-card-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.purchase-success__inner {
  max-width: 552px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.purchase-success__inner .auto-refill {
  text-align: left;
  margin-top: 40px;
}

.auto-refill .billing__update {
  margin-bottom: 20px;
}

.auto-refill .bundles-list {
  margin-bottom: 20px;
}

.auto-refill .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auto-refill .new-card {
  background-color: white;
  border: 1px solid #e5e5e5;
  margin-top: 20px;
}
