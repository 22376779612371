.box {
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 27px 24px;
  border-radius: 10px;
}

.box--alert {
  background: var(--red);
  color: white;
}

.box--alert a {
  color: white;
}
  
.tag {
  color: #666666;
  border: 1px solid #666666;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 7px;
  margin-right: 7px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  height: fit-content;
}

.tag:hover {
  background-color: #666666;
  color: white;
}

.tag--active {
  background-color: #666666;
  color: white;
}

.tag--blue {
  color: #03a9f4;
  border-color: #03a9f4;
}

.tag--blue:hover {
  background-color: #03a9f4;
}

.tag--green {
  color: #4caf50;
  border-color: #4caf50;
}

.tag--green:hover {
  background-color: #4caf50;
}

.tag--red {
  color: #f44336;
  border-color: #f44336;
}
.tag--red:hover {
  background-color: #f44336;
}

.table-button {
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

.table-button:hover {
  opacity: 0.5;
  cursor: pointer;
}

.table-button img {
  max-height: 100%;
  max-width: 100%;
  background-size: cover;
}

.button {
  padding: 13px 17px;
  background-color: #52a8ae;
  width: fit-content;
  height: fit-content;
  border-radius: 50px;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  outline: none;
}

.button:disabled {
  transition: background 0s ease-in-out;
  background: #868686;
}


.button:disabled:hover {
  transition: background 0s ease-in-out;
  background: #868686;
}

.button:hover {
  background-color: #30858d;
}

.button--small {
  padding: 4px 17px;
}

.button--big {
  padding: 17px 17px;
}

.button--outline {
  border: 2px solid #52a8ae;
  color: #52a8ae;
  background-color: transparent;
}

.button--white {
  background-color: white;
  border: 1px solid #e5e5e5;
  color: black;
}

.button--white:hover {
  background-color: white;
  border-color: #52a8ae;
  color: #52a8ae;
}

.button--green-2 {
  background-color: #4caf50;
}

.button--outline-green-2 {
  border: 2px solid #4caf50;
  color: #4caf50;
  background-color: transparent;
  font-weight: 400;
}

.button--outline:hover {
  color: white;
  background-color: #52a8ae;
}

.button--outline-green-2:hover {
  color: white;
  background-color: #4caf50;
}

.button--outline-purple {
  border: 2px solid #673ab7;
  color: #673ab7;
  background-color: transparent;
  font-weight: 400;
}
.button--outline-purple:hover {
  color: white;
  background-color: #673ab7;
}

.description {
  font-size: 12px;
  line-height: 16px;
  color: black;
  text-decoration: none;
}

.is-bold {
  font-weight: bold;
}

.hide {
  display: none;
}

.sort-arrows {
  display: flex;
  flex-direction: column;
}

.sort-arrows img {
  transition: all 0.3s ease-in-out;
}

.sort-arrows img:first-child {
  margin-bottom: 3px;
}

.sort-arrows img:hover {
  opacity: 0.6 !important;
}

.sort-arrows__active {
  opacity: 0.6 !important;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;
}

.container--small {
  max-width: 700px;
}

.credit-card {
  background-color: #c8e6c9;
  border-radius: 5px;
  /* min-height: 250px; */
  margin-top: 15px;
  padding: 16px 24px;
}

.credit-card--blue {
  background-color: #b3e5fc;
}

.credit-card__header {
  border-bottom: 1px solid #4caf50;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.credit-card__header--direction-column {
  flex-direction: column;
}

.credit-card--blue .credit-card__header {
  border-color: #03a9f4;
}

.credit-card__main {
  padding-top: 16px;
}

.credit-card__row {
  display: flex;
  justify-content: space-between;
}

.credit-card__row:not(:last-child) {
  margin-bottom: 16px;
}

.credit-card__column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.credit-card__column__title {
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.credit-card__column__item {
  margin-bottom: 5px;
}

.box-section {
  margin-bottom: 30px;
}

.link-gray {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 700;
}

.link-gray a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}

.link-gray:hover {
  color: #52a8ae !important;
  cursor: pointer;
}

.link-gray:hover a {
  color: #52a8ae;
}

.container {
  padding: 0 20px;
  margin: 0 auto;
}

.icon-button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  filter: grayscale(100%);
}

.icon-button:hover {
  opacity: 0.7;
  filter: grayscale(0);
}

.input-date-interval {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.input-date-interval__to {
  padding: 0 10px;
}

.input-date-interval .input-wrapper {
  margin-bottom: 0;
}

.input-date-interval input {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.hide {
  display: none;
}

.button-link {
  color: #52a8ae;
  text-decoration: none;
}

.button-link:hover {
  text-decoration: underline;
  color: #73c0c5;
}

.relative-parent {
  position: relative;
}

.absolute-h-center {
  position: absolute;
  top: 80px;
  max-width: 100%;
  margin-right: auto;
  right: 0;
  left: 0;
  margin-left: auto;
}

.single-form-wrapper {
  max-width: 500px;
}

.standard-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: center;
  padding: 5px;
}

.nav-logo-wrapper {
  width: 100%;
  height: 100%;
}

.nav-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.logo--bordered {
  border: 1px solid #e5e5e5;
}
