.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  width: 100%;
  position: relative;
}

.input-wrapper label {
  font-size: 14px;
  /* font-weight: 500; */
  margin-bottom: 7px;
}

.input-wrapper input,
.input-wrapper select {
  background-color: transparent;
  border: none;
  padding: 8px 3px;
  height: 44px;
  font-size: 16px;
  color: rgba(#181b20, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  text-indent: 10px;
}

.input-wrapper input[readonly=readonly] {
  background-color: #eee;
  border: 1px solid #ddd;
  pointer-events: none;
  cursor: default;
}

.input-wrapper input.input--light {
  border-color: rgb(229, 229, 229);
  background-color: #fff;
  width: 100%;
}

.input-wrapper .field_with_errors {
  display: flex;
  flex-direction: column;
}

.input-wrapper .field_with_errors input,
.input-wrapper .field_with_errors select,
.input-wrapper .field_with_errors textarea {
  border-color: #c0392b !important;
}

.input-wrapper--big input {
  height: 56px;
}

.input-wrapper select {
  appearance: none;
  position: relative;
  padding-right: 45px;
}
select::-ms-expand {
  display: none;
}

.input-wrapper--select::after {
  content: url("../../../../images/select-arrow.svg");
  color: #333;
  right: 0;
  height: 44px;
  bottom: 0;
  padding-left: 14px;
  padding-right: 15px;
  border-left: 1px solid #999;
  display: flex;
  align-items: center;

  position: absolute;
  pointer-events: none;
}

.input-wrapper textarea {
  background-color: transparent;
  border: none;
  padding: 8px 3px;
  height: 90px;
  font-size: 16px;
  color: rgba(#181b20, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  text-indent: 10px;
  resize: none;
}

.input-wrapper input::placeholder,
.input-wrapper textarea::placeholder {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b3b3b3;
  opacity: 0.78;
  font-weight: 400;
}

.input-wrapper .field_with_errors,
.input-wrapper .field_with_errors input {
  width: 100%;
}

.form-error {
  color: #c0392b;
  margin-bottom: 26px;
}

.input-wrapper--style-2 {
  width: auto;
  height: 44px;
  background-color: #fff;
}

.input-wrapper--style-2 input,
.input-wrapper--style-2 select,
.input-wrapper--style-2 textarea {
  border: 1px solid #e5e5e5;
  width: 170px;
}

.input-wrapper--style-2::after {
  border-color: #e5e5e5;
}

.toggle-checkbox {
  width: 52px;
  height: 32px;
  -webkit-appearance: none;
  outline: none;
  border-radius: 20px;
  position: relative;
  margin-right: 10px;
  transition: 0.5s;
  border: 1px solid #999;
  background: #efefef;
}
.toggle-checkbox:checked {
  background: #dafdff;
  border: 1px solid #52a8ae;
}

.toggle-checkbox:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  top: 2px;
  left: 2px;
  transition: 0.5s;
  transform: scale(0.9);
  background: #999;
}

.toggle-checkbox:checked:before {
  left: 22px;
  background: #52a8ae;
}

.input-wrapper__phone > * {
  display: block;
  margin: 0;
  float: left;
}

.input-wrapper__phone .countries-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 20%;
}

.input-wrapper__phone #user_phone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  width: 80%;
}

.input-wrapper small {
  font-size: 0.7em;
  color: #666;
  margin: 5px 0;
}

.editable-input {
  display: flex;
}

.editable-input__icon {
  width: 13px;
  color: #ccc;
  top: 2px;
  position: relative;
  cursor: pointer;
}

.editable-input input {
  flex: 1;
  margin-right: 10px;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 5px;
}

.editable-input button {
  width: 30%;
  max-width: 80px;
  min-width: 65px;
  display: inline-block;
}
