.search-input .input-wrapper {
  min-width: 250px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.search-input .input-wrapper input {
  border-color: rgb(229, 229, 229);
  background-color: #fff;
  width: 100%;
}

.search-input .input-wrapper .input-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
