.sign-up-individual {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-individual__inner {
  display: flex;
  justify-content: center;
  width: 100%;
}

.sign-up-individual__details {
  flex: 1;
  max-width: 450px;
  margin-right: 100px;
}

.sign-up-individual__details p:first-child {
  margin-top: 0;
}

.sign-up-individual__line {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.sign-up-individual__details h1 {
  font-size: 48px;
}

.sign-up-individual__details__sub-title {
  font-size: 24px;
}

.sign-up-individual__form {
  flex: 1;
  max-width: 450px;
}

.sign-up-individual__form__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sign-up-individual__form h2 {
  width: 90%;
  text-align: center;
  margin: 30px auto;
}

.sign-up-individual__form p {
  margin: 30px 0;
}

.sign-up-individual__form__details p {
  font-size: 12px;
  text-align: left;
  margin: 5px 0;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
}

.sign-up-individual__google-button {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-up-individual__google-button img {
  margin-right: 10px;
}

.sign-up-individual__form button {
  width: 100%;
  border-radius: 3px;
}

.sign-up-individual__box {
  text-align: center;
  max-width: 500px;
  width: 100%;
  margin-top: -100px;
}
.sign-up-individual__details a {
  color: #52a8ae;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.sign-up-individual__details a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.sign-up-individual__box a {
  color: #52a8ae;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.sign-up-individual__box a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.sign-up-individual__box img {
  margin-top: 20px;
}
.sign-up-individual__box h2 {
  margin-bottom: 25px;
}

@media only screen and (max-width: 1100px) {
  .sign-up-individual__details {
    width: 100%;
  }
  .sign-up-individual__inner {
    flex-direction: column;
    margin-right: 0;
  }
  .sign-up-individual__details {
    max-width: 100%;
  }
  .sign-up-individual__form {
    max-width: 100%;
  }
}
