.impersonating {
  background: #e74c3c;
  padding: 8px;
  color: white;
  text-align: center;
  font-size: 0.8em;
  font-weight: 500;

  a {
    color: #e74c3c;
    background: white;
    padding: 3px;
    border-radius: 5px;
  }
}
