.table {
  .table__column__sort {
    color: #ccc;
    font-size: 1.2em;
    
    &--active {
      color: #53a7ae;
    }
  }
}


nav.pagination {
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  display: inline-block;
  margin: auto;
  
  > span {
    &:nth-child(1) {
      > a, &.current {
        border-left: none;
      }
    }
  }

  > span > a, > span.current {
    display: inline-block;
    text-align: center;
    padding: 15px 21px;
    border-left: 1px solid #e5e5e5;
  }

  .page {
    &.current {
      color: black;
      font-weight: bold;
    }
  }
}
