.password-check {
  height: 100%;
  width: 100%;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-check__inner {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 120px);
  align-items: center;
  height: 100%;
  width: 100%;
}

.password-check__box {
  text-align: center;
  max-width: 500px;
  width: 100%;
  margin-top: -80px;
}
.password-check__box a {
  color: #52a8ae;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.password-check__box a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.password-check__box img {
  margin-top: 20px;
  max-width: 100vw;
  width: 100%;
}
.password-check__box h2 {
  margin-bottom: 25px;
}
