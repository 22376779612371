.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-grow {
  flex-grow: 1;
}

.items-center {
  align-items: center; 
}

.blur {
  filter: blur(3px);
}
