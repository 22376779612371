.sign-in {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  min-height: calc(100vh - 120px);
  overflow-x: hidden;
}

.sign-in__inner {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.sign-in__submit {
  width: 100%;
  border-radius: 3px;
}

/* .sign-in__form {
  margin-right: 120px;
} */

.sign-in__forgotten-password {
  font-size: 12px;
  margin-top: 12px;
}

.sign-in__line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 30px 0;
}

.sign-in__form__buttons {
  display: flex;
  flex-direction: column;
}

.sign-in__form__buttons button {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.sign-in__form__buttons button img {
  margin-right: 10px;
}

.sign-in__form__details {
  z-index: 100;
}

.sign-in__form__details__text p {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.sign-in__illustration {
  z-index: 10;
}
.sign-in__illustration img {
  /* width: 100%; */
}

.sign-in__bg-illustration {
  position: absolute;
  top: 0;
  right: -150px;
  z-index: 0;
}

@media only screen and (max-width: 1100px) {
  .sign-in {
    margin-top: 70px;
  }
  .sign-in__inner {
    flex-direction: column;
  }
  .sign-in__bg-illustration {
    display: none;
  }
  .sign-in__illustration img {
    width: 100%;
  }
}
