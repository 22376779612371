.progress {
  background: white;
  padding: 2px;
  border: 2px solid #53a7ae;
  border-radius: 14px;
}

.progress > div {
  height: 14px;
  width: 0%;
  background: #53a7ae;
  transition: width 0.1s ease-in-out;
  border-radius: 10px;
}
