.university-login-search {
  height: 100%;
  width: 100%;
  margin-top: 120px;
}

.university-login-search__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.university-login-search__image {
  display: flex;
  align-items: center;
  margin-left: 100px;
  position: relative;
}
.university-login-search__image img {
  margin-top: 15%;
  width: 100%;
}

.university-login-search__form {
  max-width: 460px;
}

.university-login-search__form {
  margin: 30px 0;
}

.university-login-search__form button {
  width: 100%;
  border-radius: 3px;
}

@media only screen and (max-width: 1100px) {
  .university-login-search__form h2,
  .university-login-search__form p {
    text-align: center;
  }
  .university-login-search__inner {
    flex-direction: column;
  }
  .university-login-search__image {
    margin-left: 0;
  }
}
