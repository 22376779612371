.sidebar {
  position: fixed;
  left: 0;
  width: 240px;
  height: 100vh;
  background: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.sidebar__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  width: 100%;
  height: 100%;
}

.sidebar__logo:not(.default) {
  border-radius: 100%;
  border: 2px solid rgba(82, 168, 174, 0.24);
  height: 128px;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 16px 0px rgba(82, 168, 174, 0.24);
  overflow: hidden;
}

.sidebar__logo.default {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.sidebar__logo.default a, .sidebar__logo.default img {
  width: 100%;
}

.sidebar__logo--full {
  border: none;
  box-shadow: none;
  width: 80%;
  height: 90px;
  margin-bottom: 30px;
}

.sidebar__logo--full img {
  width: 100%;
}

.sidebar__top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 2;
  width: 100%;
}

.sidebar__top--align-top {
  justify-content: flex-start;
}

.sidebar__footer {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.sidebar__links {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.sidebar-link {
  width: 100%;
  padding: 14px 30px;
  border-left: 4px solid transparent;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

.sidebar-link:hover {
  color: #52a8ae;
}

.sidebar-link:hover g,
.sidebar-link--active g {
  fill: #52a8ae;
}

.sidebar-link--rect-hover:hover rect,
.sidebar-link--active .sidebar-link--rect-hover rect {
  fill: #52a8ae;
}

.sidebar-link--rect-hover:hover g,
.sidebar-link--active .sidebar-link--rect-hover g {
  fill: transparent;
}

.sidebar-link img {
  filter: grayscale(1);
}

.sidebar-link span {
  margin-left: 10px;
}

.sidebar-link--active {
  color: #52a8ae;
  border-color: #52a8ae;
  background-color: rgba(229, 229, 229, 0.1);
}

.sidebar-link--active img {
  filter: none;
}

.sidebar-menu {
  display: none;
  z-index: 11;
}

.sidebar__user {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  margin: 30px 0;
}

.sidebar__user__image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.sidebar__user__name {
  margin-top: 20px;
  font-size: 16px;
}

@media only screen and (max-width: 900px) {
  .sidebar {
    transform: translateX(-100%);
  }
  .sidebar-menu {
    display: block;
    position: fixed;
    top: 10px;
    left: 7px;
    padding: 2px;
    border-radius: 5px;
  }
}

.sidebar--active {
  transform: translateX(0);
}
