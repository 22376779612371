.error {
  color: #e74c3c;
  font-size: 0.8em;
}

.text-semibold {
  font-weight: 600;
}

.link--unstyled {
  color: inherit;  
}

.link--unstyled:hover {
  color: inherit;
}

.text--error {
  font-size: 0.8em;
  color: #e74c3c;
  margin: 5px 0;
}

.application__onboarding h1,
.application__onboarding h2,
.application__onboarding h3,
.application__onboarding h4,
.application__onboarding h5,
.application__onboarding h6 {
 font-weight: bold;
}
