$changes-width: 450px;
$animation-duration: 150ms;
$spacing-sm: 15px;

.transcript-editor {
  display: flex;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  
  &__panel--editor {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    transition: right $animation-duration ease-in-out;
  }

  &__panel--changes {
    background: white;
    position: absolute;
    width: $changes-width;
    top: 0; bottom: 0; right: -$changes-width;
    transition: right $animation-duration ease-in-out;
    overflow-y: scroll;
    border-left: 2px solid #ddd;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }

  &--changes-active {
    .transcript-editor__panel--editor {      
      right: $changes-width;
    }

    .transcript-editor__panel--changes {
      right: 0;
    }
  }

  &__notice {
    right: 0; left: 0;
    display: flex;
    justify-content: center;

    > div {
      border: 1px solid mix(#53a7ae, white, 40%);
      color: #53a7ae;
      background: mix(#53a7ae, white, 10%);
      padding: 5px 5px 5px 30px;
      display: flex;
      align-items: center;
      font-weight: 600;
      z-index: 1;
      border-radius: 0 0 8px 8px;
      border-top: none;
      
      > p {
        margin: 0;
        padding: 0;
        flex-grow: 1;
      }
      
      > a, input {
        border: 1px solid #53a7ae;
        padding: 3px 6px;
        color: white;
        background: #53a7ae;
        border-radius: 6px;
        margin-left: 30px;
      }
    }
  }
}

.transcript-editor__panel--changes__header {
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid #ddd;
  text-align: center;
  padding: $spacing-sm 0;
  font-weight: 600;
}

.transcript-editor__panel--changes__setting {
  display: flex;
  align-items: center;
  > span { flex-grow: 1; }
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.transcript-editor__panel__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  
  > svg { width: 20px; height: 20px; }
}

.transcript-editor__change-list {
  list-style: none;
  margin: 0; padding: 0;
}

.transcript-editor__change-group {
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: $spacing-sm;

  > div { flex: 1 1 0%; }

  &--highlighted {
    background-color: #FEFCE8;
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;

    > * { margin: 0; padding: 0; }
    > h3 { font-size: 20px; font-weight: 600; }
  }

  &__content {
    line-height: 1.7em;
  }
  
  &__avatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: $spacing-sm;
  }
}

.transcript-editor__diff {
  border-radius: 2px;
  padding: 2px 4px;

  &--red {
    color: #DC2626;
    background-color: #FEF2F2;
  }

  &--green {
    color: #059669;
    background-color: #DCFCE7;
  }
}

span.Word[data-prev-times~="0"] {
  color: #444 !important;
}

.transcript-editor__panel--editor {
  section { margin: 0; }
}

.transcript-editor--with-change-highlighting {
  .transcript-editor__highlight {
    padding: 0 3px;
    cursor: pointer;
    background-color: #FEF9C3;
  }
}

.transcript-editor--with-confidence-highlighting {
  span.Word[data-confidence="low"] {
    color: #ff4500 !important;
    border-bottom: 2px dotted #ff4500;
  }
}

.transcript-editor__loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    > svg {
      padding-left: 35px;
      width: 300px;
      height: 300px;
    }
  }
}

.transcript-editor {
  .video-dimensions, video {
    width: 100%;
    height: auto;
  }
}

button[title="Show Changes"] {
  display: none;
}

.transcript-editor--editable {
  button[title="Show Changes"] {
    display: inline-block;
  }
}

.vjs-text-track-display {
  display: none;
}
