.uni-not-registered {
  height: 100%;
  width: 100%;
  margin-top: 120px;
}

.uni-not-registered__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uni-not-registered__image {
  display: flex;
  align-items: center;
  margin-left: 100px;
}
.uni-not-registered__image img {
  width: 100%;
  margin-top: 15%;
}

.uni-not-registered__form {
  max-width: 460px;
}

.uni-not-registered__form button {
  width: 100%;
  border-radius: 3px;
}

@media only screen and (max-width: 1100px) {
  .uni-not-registered__inner {
    flex-direction: column;
  }
  .uni-not-registered__image {
    margin-left: 0;
  }
}
