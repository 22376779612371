.sign-up {
  height: 100%;
  width: 100%;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sign-up .navbar {
  z-index: 100;
}

.sign-up__inner {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 120px);
  align-items: center;
  height: 100%;
  width: 100%;
}

.sign-up__bg-illustration {
  position: absolute;
  top: 0;
  right: -150px;
  z-index: 0;
}

.sign-up__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up__main {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  z-index: 1;
  text-align: center;
}
.sign-up__title {
  margin-bottom: 3px;
}

.sign-up__tooltip {
  font-size: 14px;
  border: 1px solid rgba(82, 168, 174, 0.24);
  box-shadow: 4px 0px 16px 0px rgba(82, 168, 174, 0.24);
  padding: 6px 24px;
  background-color: white;
  position: relative;
}

.sign-up__tooltip::after {
  position: absolute;
  content: " ";
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.sign-up__tooltip__container {
  margin-top: 30px;
}

.sign-up__boxes {
  margin-top: 50px;
  display: flex;
}

.sign-up__box {
  display: block;
  width: 256px;
}

.sign-up__box:first-child {
  margin-right: 24px;
}

.sign-up__box__header {
  width: 256px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.sign-up__box__header .sign-up__box__header__bg {
  width: 256px;
}

.sign-up__box__header__person {
  position: absolute;
  bottom: -1px;
  right: 0;
}

.sign-up__box__content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #c8e6c9;
  background: white;
}

.sign-up__box__content:hover {
  box-shadow: 0 0 50px rgba(154, 240, 225, 0.5);
}

.sign-up__box--blue .sign-up__box__content {
  border: 1px solid #b3e5fc;
}

.sign-up__box--blue .sign-up__box__header__person {
  left: 0;
  right: unset;
}

.sign-up__box__title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.sign-up__box__description {
  font-size: 14px;
}

.sign-up__help-link {
  margin-top: 30px;
  margin-bottom: 10px;
}

.sign-up__question {
  font-weight: 500;
}
.sign-up__question .button-link {
  text-decoration: underline;
}

@media only screen and (max-width: 1100px) {
  .sign-up__bg-illustration {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .sign-up__boxes {
    display: flex;
    flex-direction: column;
  }
  .sign-up__box {
    width: 100%;
    margin: 20px;
    margin-right: 0;
    margin-left: 0;
  }
  .sign-up__box__header {
    width: 100%;
  }
  .sign-up__box__header .sign-up__box__header__bg {
    width: 100%;
  }
}
