.checkbox-wrapper {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checkbox-container {
  display: flex;
}

.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  clear: both;
  margin-right: 5px;
}

.checkbox-label.checkbox-label--left {
  margin: 0;
  width: auto;
  padding-left: 30px;
  font-size: 14px;
  font-weight: normal;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.checkbox-label input:checked ~ .checkbox-custom {
  border-radius: 3px;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  background-color: #4caf50;
  border: 1px solid #4caf50;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  height: 0px;
  width: 0px;
  border-radius: 3px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 3px;
  transform: scale(0);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
}

/* Style for Circular Checkbox */
.checkbox-label .checkbox-custom.circular {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkbox-label input:checked ~ .checkbox-custom.circular {
  border-radius: 50%;
  background-color: #4caf50;
  border: 1px solid #4caf50;
}
.checkbox-label input:checked ~ .checkbox-custom.circular::after {
  border: solid white;
  border-width: 0 2px 2px 0;
}
.checkbox-label .checkbox-custom.circular::after {
  border-radius: 50%;
}

.checkbox-label .checkbox-custom.circular::before {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkbox-label input:checked ~ .checkbox-custom.circular::before {
  border-radius: 50%;
}
