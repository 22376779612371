.accounts-details {
  background: #f7f7f7;
}

.sides__wrapper {
  display: flex;
}

h1 {
  letter-spacing: 1px;
}
.input-wrapper {
  margin-right: 20px;
}

.details__down {
  display: flex;
}

.details__up .input-wrapper:last-child {
  margin-right: 0;
}

.details__down .input-wrapper:last-child {
  margin-right: 0;
}

.details__up {
  display: flex;
}

.linked__buttons {
  display: flex;
  justify-content: flex-end;
}

.linked__buttons .button {
  margin-left: 10px;
}

.details__title,
.account_integrations__title {
  margin: 30px 0;
}

.details__buttons {
  display: flex;
  justify-content: flex-end;
}

.billing {
  padding: 20px;
  background: #b3e5fc;
  margin-top: -58px;
  border-radius: 3px;
  border-radius: 5px;
}

.billing__time-payment {
  display: flex;
  justify-content: space-between;
}

.billing__time {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.billing__time__number {
  font-size: 24px;
}

.billing__time__text {
  font-size: 12px;
}

.billing span {
  font-weight: 500;
}
.billing p {
  font-size: 12px;
  color: #020202;
}
.billing__update {
  display: flex;
  align-items: center;
}

.billing__checkbox {
  width: 52px;
  height: 32px;
  -webkit-appearance: none;
  outline: none;
  border-radius: 20px;
  position: relative;
  margin: 0 20px;
  transition: 0.5s;
  border: 1px solid #52a8ae;
  background: #fff;
}
.billing__checkbox:checked {
  background: #dafdff;
}

.billing__checkbox:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  top: 2px;
  left: 2px;
  transition: 0.5s;
  transform: scale(0.9);
  background: #52a8ae;
}

.billing__checkbox:checked:before {
  left: 22px;
}

.billing__advantage {
  display: flex;
  align-items: center;
}

.billing__advantages {
  margin-top: 20px;
}

.billing__advantage input {
  margin-right: 16px;
  margin-left: 5px;
  -webkit-appearance: none;
  position: relative;
  background: white;
  outline: none;
  width: 18px;
  height: 18px;
  border: 2px solid #52a8ae;
  border-radius: 50%;
}

.billing__advantage input:checked {
  border-radius: 50%;
  background: white;
}

.billing__advantage input:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  top: 3px;
  left: 3px;
}

.billing__advantage input:checked:before {
  background: #52a8ae;
}

.linked__payment {
  margin-top: 40px;
}

.linked__payment-inner {
  display: flex;
}
/*.box .table {
  margin-top: 10px;
}*/
.account__linked-up {
  display: flex;
  align-items: center;
}

.account__linked-up p {
  font-size: 16px;
  font-weight: medium;
}
.account__title {
  margin: 15px 0 30px;
}
.linked__img {
  margin-right: 20px;
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.account__linked-down {
  display: flex;
  margin-top: 20px;
}
.account__linked-down .input-wrapper:last-child {
  margin-right: 0;
}

.payment__card-down {
  margin-top: 20px;
}

.payment__card-up {
  display: flex;
  align-items: center;
}

.payment__code p {
  padding: 0;
  margin: 10px 0;
}
.payment__title {
  margin: 30px 0;
}

.payment__img {
  height: 82px;
  width: 82px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.payment__img img {
  max-width: 80%;
}
.payment__code__name {
  font-size: 16px;
}
.payment__name {
  display: flex;
}

.payment__name .name {
  font-weight: bold;
}

.payment__name .name {
  margin-right: 35px;
}

.update__details {
  margin-top: 10px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 0 14px;
  border-radius: 40px;
}
.support__receipts {
  display: flex;
  margin-top: 40px;
}

.support__link {
  text-decoration: none;
  color: #52a8ae;
}

.support__link:hover {
  color: #6dbfc5;
}

.support__inner {
  display: flex;
  justify-content: center;
}

.right__side {
  margin-left: 20px;
}
.receipts__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support__img {
  margin-right: 40px;
  height: 82px;
  width: 82px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}
.support__title {
  margin: 30px 0;
}
.support__description p {
  font-size: 12px;
  line-height: 16px;
  color: #020202;
}

.support__description p:first-child {
  margin-bottom: 10px;
}

/*.table {
  width: 552px;
}

.td {
  border: none;
}
*/
.receipts .table .no__border {
  border: none;
}

.receipts__title {
  margin: 30px 0;
}

.receipts__link {
  text-decoration: none;
  color: #666666;
  font-weight: bold;
}

.receipts .box {
  padding: 0;
}

.receipts .table {
  margin-bottom: 0;
}

.delete__account,
.account__integrations {
  margin-top: 30px;
}

.delete__sides-wrapper,
.account__integrations-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.account__integrations-wrapper > img {
  margin-right: 20px;
}

.delete__left-side p {
  font-size: 12px;
  width: 80%;
  line-height: 16px;
  color: #020202;
}
